import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const EMODE = {
  DEV: 'dev',
  TEST: 'test',
  PROD: 'prod'
}

export const initLogs = () => {
  const params = {
    site: 'ap1.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  }
  switch (process.env.ENV) {
    case EMODE.PROD:
      datadogLogs.init({
        ...params,
        clientToken: 'pubf9f58eaafad3eec60c6338fa08cd4a15',
        service: 'prod-web',
        env: EMODE.PROD
      })
      break
    case EMODE.TEST:
      datadogLogs.init({
        ...params,
        clientToken: 'pub86b0263af14807817d3c2e9d7b12c129',
        service: 'test-web',
        env: EMODE.TEST
      })
      break
  }
}

export const initRum = () => {
  const params = {
    site: 'ap1.datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input'
  }
  switch (process.env.ENV) {
    case EMODE.PROD:
      datadogRum.init({
        ...params,
        applicationId: '346d781a-1c65-45e0-9196-c4325b0f304c',
        clientToken: 'pubf9f58eaafad3eec60c6338fa08cd4a15',
        service: 'prod-web',
        env: EMODE.PROD
      })
      break
    case EMODE.TEST:
      datadogRum.init({
        ...params,
        applicationId: '4400e359-e852-4bb5-b2ed-f462b1baf180',
        clientToken: 'pub86b0263af14807817d3c2e9d7b12c129',
        service: 'test-web',
        env: EMODE.TEST
      })
      break
  }
}

export const setUser = (userInfo = {}) => {
  if ([EMODE.PROD, EMODE.TEST].includes(process.env.ENV)) {
    datadogRum.setUser(userInfo)
  }
}
